import React, { Component } from "react"
import * as CON from "../../common/Constants"
import { LineGraph } from "./LineGraph"
import PlayersTable from "./PlayersTable"
import ServerTable from "./ServerTable"
import MapPreview from "./MapPreview"
import "./ServerBox.css"

export default class ServerBox extends Component {
    constructor(props) {
        super(props)
        //this.state = props;
        this.info = props.serverinfo
        this.setLastUpdate()
    }

    componentWillReceiveProps(props) {
        this.info = props.serverinfo
        this.setLastUpdate()
    }

    setLastUpdate() {
        let d = new Date(this.info.lastupdate * 1000)
        let strdate =
            d.getFullYear() +
            "-" +
            (d.getMonth() + 1).toString().padStart(2, "0") +
            "-" +
            d
                .getDate()
                .toString()
                .padStart(2, "0")
        let strtime =
            d
                .getHours()
                .toString()
                .padStart(2, "0") +
            ":" +
            d
                .getMinutes()
                .toString()
                .padStart(2, "0") +
            ":" +
            d
                .getSeconds()
                .toString()
                .padStart(2, "0")
        let nowdate = Math.floor(Date.now() / 1000)
        if (nowdate - this.info.lastupdate > 3600) {
            this.info.laststrupdate = strdate + " " + strtime
        } else {
            if (nowdate - this.info.lastupdate < 60) {
                this.info.laststrupdate = "few seconds ago"
            } else if (nowdate - this.info.lastupdate < 120) {
                this.info.laststrupdate = "one minute ago"
            } else {
                this.info.laststrupdate = strtime
            }
        }
    }

    info
    serverboxDOM

    render() {
        if (this.info.deltaupdate < 7200 || true) {
            return (
                <div
                    ref={elem => {
                        this.serverboxDOM = elem
                    }}
                    className={
                        "serverwrap" +
                        (this.props.expand ? " expanded" : "") +
                        (this.info.deltaupdate < 200 || true
                            ? ""
                            : " serverwrap-offline")
                    }
                    onClick={() => {
                        this.props.clickaction(this.props.id, this.serverboxDOM)
                    }}
                >
                    <div className="innerwrap">
                        <div className="mapwrap">
                            <MapPreview
                                gametime={this.info.gametime}
                                faction1={this.info.faction1}
                                faction2={this.info.faction2}
                                mappreviewdata={this.info.mappreviewdata}
                            />
                        </div>
                        <div className="infowrap">
                            <div className="servername">
                                {CON.servernames[this.info.name]}
                            </div>
                            <div className="mapname">{this.info.mapname}</div>
                            <div className="servertablewrap">
                                <ServerTable
                                    updatefromclick={this.props.updatefromclick}
                                    serverinfo={this.info}
                                />
                            </div>
                            <div
                                className={
                                    "utwrap" +
                                    (this.info.deltaupdate < 200
                                        ? " active"
                                        : " inactive")
                                }
                            >
                                Last update: {this.info.laststrupdate}
                            </div>
                        </div>
                        <div className="graphwrap" title="# of Players">
                            <LineGraph
                                updatefromclick={this.props.updatefromclick}
                                theme={this.props.theme}
                                graphdata={this.info.graphdata}
                                faction1={this.info.faction1}
                                faction2={this.info.faction2}
                            />
                        </div>
                    </div>
                    <div className="playerstablewrap">
                        <PlayersTable
                            updatefromclick={this.props.updatefromclick}
                            serverinfo={this.info}
                            factionindex={0}
                        />
                        <PlayersTable
                            updatefromclick={this.props.updatefromclick}
                            serverinfo={this.info}
                            factionindex={1}
                        />
                    </div>
                </div>
            )
        } else {
            return (
                <div className="offlineserverwrap">
                    <div className="servername">
                        {CON.servernames[this.info.name]}
                        <div className="offlineimgwrap">
                            {CON.failoverelems[this.props.id]}
                        </div>
                    </div>
                </div>
            )
        }
    }
}
