import React from "react"

export const DEV = true

export const servernames = {
    s1: "Server #1 - eu1.eutw.net:2302",
    s2: "Server #2 - eu2.eutw.net:2302",
    s3: "Server #3 - eu3.eutw.net:2302",
    s4: "Server #4 - eu4.eutw.net:2302",
    ".": "."
}

export const failoverelems = [
    <a
        href="https://www.gametracker.com/server_info/178.33.157.28:2302/"
        target="_blank"
        rel="noopener noreferrer"
    >
        <img
            src="https://cache.gametracker.com/server_info/178.33.157.28:2302/b_560_95_1.png"
            border="0"
            width="560"
            height="95"
            alt=""
        />
    </a>,

    <a
        href="https://www.gametracker.com/server_info/eu2.eutw.net:2302/"
        target="_blank"
        rel="noopener noreferrer"
    >
        <img
            src="https://cache.gametracker.com/server_info/eu2.eutw.net:2302/b_560_95_1.png"
            border="0"
            width="560"
            height="95"
            alt=""
        />
    </a>,

    <a
        href="https://www.gametracker.com/server_info/91.121.245.88:2302/"
        target="_blank"
        rel="noopener noreferrer"
    >
        <img
            src="https://cache.gametracker.com/server_info/91.121.245.88:2302/b_560_95_1.png"
            border="0"
            width="560"
            height="95"
            alt=""
        />
    </a>,

    <a
        href="https://www.gametracker.com/server_info/91.121.245.89:2302/"
        target="_blank"
        rel="noopener noreferrer"
    >
        <img
            src="https://cache.gametracker.com/server_info/91.121.245.89:2302/b_560_95_1.png"
            border="0"
            width="560"
            height="95"
            alt=""
        />
    </a>
]

/*export const serverMockData = {
    starttime: Math.ceil(Date.now()/1000),
    name: ".",
    mapname: ".",
    gametime: "00:00",
    lastupdate: Math.floor(Date.now()/1000),
    deltaupdate: 1,
    faction1: "",
    subfaction1: "",
    faction1avgexp: 1,
    faction1avgpopratio: 0.5,
    faction2: "",
    subfaction2: "",
    faction2avgexp: 1,
    faction2avgpopratio: 0.5,
    players: {
        faction1: [],
        faction2: []
    },
    graphdata: {
        time: [0, 300],
        playerscount: {
            faction1: [0,0],
            faction2: [0,0]
        },
        playersexp: {
            faction1: [0,0],
            faction2: [0,0]
        }
    },
    mappreviewdata: {
        mapimgurl: "",
        mapwidth: 100,
        towns: [],
        townshistory: []
    },
    processingtime: {
        "mock": 1
    }
}

export const smbannerconfigbak = (()=>{
    try{
        return JSON.parse(localStorage.getItem("smbannerconfig"));
    }catch(err){
        return false;
    }
})();

export const dnbannerconfigbak = (()=>{
    try{
        return JSON.parse(localStorage.getItem("dnbannerconfig"));
    }catch(err){
        return false;
    }
})();

export const donationsdatabak = (()=>{
    try{
        return JSON.parse(localStorage.getItem("donationsdata"));
    }catch(err){
        return false;
    }
})();*/
