export default function requestdata(url){
    return fetch(url,{cache: "no-cache"})
    /*.then(
        response => {
            var asd = new Promise((resolve,reject)=>{
                setTimeout(()=>resolve(response),5000)
            }) 
            return asd
        }
    )*/.then(
        response => {
            return response.json()
        }
    ).catch((err)=>{
        console.log(err);
        return false;
    })
}