import React, { Component } from 'react';
import {Doughnut} from 'react-chartjs-2';
import "./WeekStats.css"

export default class WeekStats extends Component{
    constructor(props){
        super(props);
        this.updatedata(props.data);
    }

    shouldComponentUpdate(nextprops){
        if(nextprops.updatefromclick || JSON.stringify(this.props)===JSON.stringify(nextprops)){
            return false;
        }
        this.updatedata(nextprops.data);
        return true;
    }

    updatedata(data){
        this.playedgamesdata.labels = data.playedgames.labels;
        this.playedgamesdata.datasets[0].data = data.playedgames.data;
        for(let i=0; i<this.playedgamesdata.labels.length; i++){
            this.playedgamesdata.datasets[0].backgroundColor.push(this.getColor(this.playedgamesdata.labels[i]));
        }

        this.wongamesdata.labels = data.wongames.labels;
        this.wongamesdata.datasets[0].data = data.wongames.data;
        for(let i=0; i<this.wongamesdata.labels.length; i++){
            this.wongamesdata.datasets[0].backgroundColor.push(this.getColor(this.wongamesdata.labels[i]));
        }

        this.mhqlostdata.labels = data.mhqlost.labels;
        this.mhqlostdata.datasets[0].data = data.mhqlost.data;
        for(let i=0; i<this.mhqlostdata.labels.length; i++){
            this.mhqlostdata.datasets[0].backgroundColor.push(this.getColor(this.mhqlostdata.labels[i]));
        }

        this.purchasedmipsdata.labels = data.purchasedmips.labels;
        this.purchasedmipsdata.datasets[0].data = data.purchasedmips.data;
        for(let i=0; i<this.purchasedmipsdata.labels.length; i++){
            this.purchasedmipsdata.datasets[0].backgroundColor.push(this.getColor(this.purchasedmipsdata.labels[i]));
        }

        this.killsdata.labels = data.kills.labels;
        this.killsdata.datasets[0].data = data.kills.data;
        for(let i=0; i<this.killsdata.labels.length; i++){
            this.killsdata.datasets[0].backgroundColor.push(this.getColor(this.killsdata.labels[i]));
        }
    }

    playedgamesdata = {
        labels:["fac1","fac2","fac3"],
        datasets: [
            {   label: "Sessions completed",
                data: [50,30,20],
                backgroundColor: [],
                borderColor: this.props.theme==="dark"?"rgba(40,40,40,1)":"rgba(235,235,235,1)"
            }
        ]
    };
    wongamesdata = {
        labels:[],
        datasets: [
            {   label: "Sessions won",
                data: [],
                backgroundColor: [],
                borderColor: this.props.theme==="dark"?"rgba(40,40,40,1)":"rgba(235,235,235,1)"
            }
        ]
    };
    mhqlostdata = {
        labels:["fac1","fac2","fac3"],
        datasets: [
            {   label: "mhqs lost",
                data: [50,30,20],
                backgroundColor: [],
                borderColor: this.props.theme==="dark"?"rgba(40,40,40,1)":"rgba(235,235,235,1)"
            }
        ]
    };
    purchasedmipsdata = {
        labels:["fac1","fac2","fac3"],
        datasets: [
            {   label: "purchased mips",
                data: [50,30,20],
                backgroundColor: [],
                borderColor: this.props.theme==="dark"?"rgba(40,40,40,1)":"rgba(235,235,235,1)"
            }
        ]
    };
    killsdata = {
        labels:["fac1","fac2","fac3"],
        datasets: [
            {   label: "kills",
                data: [50,30,20],
                backgroundColor: [],
                borderColor: this.props.theme==="dark"?"rgba(40,40,40,1)":"rgba(235,235,235,1)"
            }
        ]
    };

    getColor(faction){
        if(faction === "Blufor"){
            return 'rgba(0,0,255,0.8)';
        }else if(faction === "Opfor"){
            return 'rgba(255,0,0,0.8)';
        }else if(faction === "Independent"){
            return 'rgba(0,150,0,0.8)';
        }
    }

    iswindowsmall = window.innerWidth<1300 ? 0 : 1;

    render(){
        return(
        <div id="weekstatswrap">
        <div id="weekstatsinnerwrap">
            <div id="weekstatstitle">
                Seven days statistics
            </div>
            <div id="graphswrap">
                <div className="doughwrap">
                    <Doughnut
                        data={this.playedgamesdata}
                        width={250}
                        height={250}
                        options={{
                            animation:{duration: 400*this.iswindowsmall},
                            hover:{animationDuration: 0},
                            legend:{display: false},
                            title: {
                                display : true,
                                text: "Sessions completed"
                            },
                            responsive: true,
                            maintainAspectRatio: false,
                            tooltips: {
                                callbacks: {
                                    label: (item, data)=>{
                                        return data.labels[item.index] + ": " + data.datasets[0].data[item.index] 
                                        + " (" + Math.round(data.datasets[0].data[item.index]*100/(data.datasets[0].data.reduce((a,b)=>{return a+b}, 0))) + "%)";
                                    }
                                }
                            }
                        }}
                    />
                </div>
                <div className="doughwrap">
                    <Doughnut
                        data={this.wongamesdata}
                        width={250}
                        height={250}
                        options={{
                            animation:{duration: 600*this.iswindowsmall},
                            hover:{animationDuration: 0},
                            legend:{display: false},
                            title: {
                                display : true,
                                text: "Sessions won"
                            },
                            responsive: true,
                            maintainAspectRatio: false,
                            tooltips: {
                                callbacks: {
                                    label: (item, data)=>{
                                        return data.labels[item.index] + ": " + data.datasets[0].data[item.index] 
                                        + " (" + Math.round(data.datasets[0].data[item.index]*100/(data.datasets[0].data.reduce((a,b)=>{return a+b}, 0))) + "%)";
                                    }
                                }
                            }
                        }}
                    />
                </div>
                <div className="doughwrap">
                    <Doughnut
                        data={this.mhqlostdata}
                        width={250}
                        height={250}
                        options={{
                            animation:{duration: 800*this.iswindowsmall},
                            hover:{animationDuration: 0},
                            legend:{display: false},
                            title: {
                                display : true,
                                text: "MHQs lost"
                            },
                            responsive: true,
                            maintainAspectRatio: false,
                            tooltips: {
                                callbacks: {
                                    label: (item, data)=>{
                                        return data.labels[item.index] + ": " + data.datasets[0].data[item.index] 
                                        + " (" + Math.round(data.datasets[0].data[item.index]*100/(data.datasets[0].data.reduce((a,b)=>{return a+b}, 0))) + "%)";
                                    }
                                }
                            }
                        }}
                    />
                </div>
                <div className="doughwrap">
                    <Doughnut
                        data={this.purchasedmipsdata}
                        width={250}
                        height={250}
                        options={{
                            animation:{duration: 1000*this.iswindowsmall},
                            hover:{animationDuration: 0},
                            legend:{display: false},
                            title: {
                                display : true,
                                text: "MIPs purchased"
                            },
                            responsive: true,
                            maintainAspectRatio: false,
                            tooltips: {
                                callbacks: {
                                    label: (item, data)=>{
                                        return data.labels[item.index] + ": " + data.datasets[0].data[item.index] 
                                        + " (" + Math.round(data.datasets[0].data[item.index]*100/(data.datasets[0].data.reduce((a,b)=>{return a+b}, 0))) + "%)";
                                    }
                                }
                            }
                        }}
                    />
                </div>
                <div className="doughwrap">
                    <Doughnut
                        data={this.killsdata}
                        width={250}
                        height={250}
                        options={{
                            animation:{duration: 1200*this.iswindowsmall},
                            hover:{animationDuration: 0},
                            legend:{display: false},
                            title: {
                                display : true,
                                text: "Kills"
                            },
                            responsive: true,
                            maintainAspectRatio: false,
                            tooltips: {
                                callbacks: {
                                    label: (item, data)=>{
                                        return data.labels[item.index] + ": " + data.datasets[0].data[item.index] 
                                        + " (" + Math.round(data.datasets[0].data[item.index]*100/(data.datasets[0].data.reduce((a,b)=>{return a+b}, 0))) + "%)";
                                    }
                                }
                            }
                        }}
                    />
                </div>
            </div>
        </div>
        </div>
        )
    }
}