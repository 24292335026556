import React, { Component } from 'react';
import ReactTable from 'react-table';
import 'react-table/react-table.css';


export default class ServerTable extends Component{
    constructor(props){
        super(props);
        this.componentWillReceiveProps(props);
    }


    componentWillReceiveProps(props){
        let fac1pl = props.serverinfo.players.faction1;
        let fac1plcount =  fac1pl.length;
        let fac1plexp =  Math.round(fac1pl.reduce((sum,cur)=>{return(sum+cur.exp)},0));
        let fac2pl = props.serverinfo.players.faction2;
        let fac2plcount =  fac2pl.length;
        let fac2plexp =  Math.round(fac2pl.reduce((sum,cur)=>{return(sum+cur.exp)},0));
        this.formatteddata = [
            {
                faction: props.serverinfo.faction1,
                plcount: fac1plcount,
                plexp: fac1plexp,
                plavgexp: fac1plcount>0 ? Math.round((fac1plexp/fac1plcount) * 10) / 10 : 0,
                plchronexp: Math.round(props.serverinfo.faction1avgexp*10)/10,
                plrifleman: fac1pl.reduce((sum,cur)=>{return(sum + (cur.c==="R"?1:0))} , 0) + "/15",
                plengineer: fac1pl.reduce((sum,cur)=>{return(sum + (cur.c==="E"?1:0))} , 0) + "/5",
                plsniper: fac1pl.reduce((sum,cur)=>{return(sum + (cur.c==="S"?1:0))} , 0) + "/5",
                plmedic: fac1pl.reduce((sum,cur)=>{return(sum + (cur.c==="M"?1:0))} , 0) + "/5"
            },{
                faction: props.serverinfo.faction2,
                plcount: fac2plcount,
                plexp: fac2plexp,
                plavgexp: fac2plcount>0 ? Math.round((fac2plexp/fac2plcount) * 10) / 10 : 0,
                plchronexp: Math.round(props.serverinfo.faction2avgexp*10)/10,
                plrifleman: fac2pl.reduce((sum,cur)=>{return(sum + (cur.c==="R"?1:0))} , 0) + "/15",
                plengineer: fac2pl.reduce((sum,cur)=>{return(sum + (cur.c==="E"?1:0))} , 0) + "/5",
                plsniper: fac2pl.reduce((sum,cur)=>{return(sum + (cur.c==="S"?1:0))} , 0) + "/5",
                plmedic: fac2pl.reduce((sum,cur)=>{return(sum + (cur.c==="M"?1:0))} , 0) + "/5"
            }
        ];

        let exp0 = this.formatteddata[0].plexp;
        let exp1 = this.formatteddata[1].plexp
        this.expwarning = 0;
        if(Math.abs(exp0-exp1) > 40){
            this.expwarning = 2;
            this.suggestiontext = "Suggested joining "+ (exp0>exp1 ? this.formatteddata[1].faction : this.formatteddata[0].faction)
        }else if(Math.abs(exp0-exp1) > 20){
            this.expwarning = 1;
            this.suggestiontext = "Suggested joining "+ (exp0>exp1 ? this.formatteddata[1].faction : this.formatteddata[0].faction)
        }
    }

    shouldComponentUpdate(nextprops){
        if(nextprops.updatefromclick || JSON.stringify(this.props.serverinfo)===JSON.stringify(nextprops.serverinfo)){
            return false;
        }
        return true;
    }

    formatteddata;
    expwarning = 0;
    suggestiontext = "";
    svgdpath = "m8.33 11.6c0 0.222-0.0422 0.42-0.127 0.595-0.0845 0.169-0.198 0.312-0.341 0.429-0.143 0.111-0.31 0.196-0.5 0.254-0.191 0.064-0.391 0.095-0.603 0.095-0.159 0-0.315-0.02-0.468-0.064-0.16-0.1-0.29-0.1-0.42-0.2-0.11-0.1-0.21-0.2-0.28-0.4-0.07-0.1-0.1-0.3-0.1-0.5 0-0.217 0.0423-0.41 0.127-0.579 0.0898-0.174 0.206-0.32 0.349-0.436 0.148-0.122 0.317-0.212 0.508-0.27 0.191-0.063 0.389-0.095 0.595-0.095 0.148 0 0.296 0.02 0.444 0.063 0.154 0.042 0.291 0.111 0.413 0.206 0.121 0.09 0.219 0.206 0.293 0.349 0.0743 0.143 0.111 0.315 0.111 0.516zm-0.88-2.31c-0.04 0.04-0.11 0.07-0.19 0.1-0.16 0.07-0.33 0.1-0.49 0.15-0.08 0.02-0.16 0.04-0.22 0.06l-0.23-0.17-0.69-7.59c0.75-0.4 1.39-0.66 2.11-0.968l0.42 0.278z";

    setColor(faction){
        if(faction === "Blufor"){
            return 'rgba(0,0,240,1)'
        }else if(faction === "Opfor"){
            return 'rgba(240,0,0,1)'
        }else if(faction === "Independent"){
            return 'rgba(0,200,0,1)'
        }
    }

    render(){
        return <ReactTable
                data={this.formatteddata}
                resizable = {false}
                sortable = {false}
                getTheadThProps={(state, row, column)=>{
                    if(column.id==="plcount"){
                        return {title:"Player count"};
                    }
                    else if(column.id==="plexp"){
                        return {title:"Current cumulated experience"};
                    }
                    else if(column.id==="plavgexp"){
                        return {title:"Exp/Players"};
                    }
                    else if(column.id==="plchronexp"){
                        return {title:"Similar to Avg exp but taking\nin account also previous state"};
                    }
                    return {};
                }}
                getTrProps={(state, row, column) => {
                    return {
                        style: {
                            color: this.setColor(row.row.faction)
                        }
                    }
                }}
                getTdProps={(state,row,column)=>{
                    if(column.id==="plexp" && this.expwarning!==0){
                        if(this.expwarning === 1){return {title: "Unbalanced teams. "+this.suggestiontext, style: {textAlign: "center"}}}
                        else {return {title: "Strongly unbalanced teams. "+this.suggestiontext, style: {textAlign: "center"}}}
                    }else if(column.id==="faction"){
                        return {style: {textAlign: "left"}}
                    }else{
                        return {}
                    }
                }}
                style= {{
                    flex: "1",
                    textAlign: "center"
                }}
                columns={
                    [{
                        Header: 'Factions',
                        accessor: "faction",
                        minWidth: 85
                    },{
                        Header: 'Players',
                        accessor: "plcount",
                        minWidth: 85
                    },{
                        Header: 'Exp',
                        accessor: "plexp",
                        minWidth: 80,
                        Cell: row=>{
                            if(this.expwarning===1){
                                return <div style={{position:"relative"}}>
                                    <svg style={{position:"absolute", left:"3px"}} xmlns={"http://www.w3.org/2000/svg"} height={20} width={20} version={1.1} viewBox={"0 0 14 14"}><path fill={"#ff9900"} d={this.svgdpath}/>
                                    </svg>
                                    {row.value}
                               </div>
                            }else if(this.expwarning===2){
                                return <div style={{position:"relative"}}>
                                    <svg style={{position:"absolute", left:"3px"}} xmlns={"http://www.w3.org/2000/svg"} height={20} width={20} version={1.1} viewBox={"0 0 14 14"}><path fill={"#ff0000"} d={this.svgdpath}/>
                                    </svg>
                                    {row.value}
                               </div>
                            }else {
                                return row.value
                            }
                        }
                    },{
                        Header: 'Avg exp',
                        accessor: "plavgexp",
                        minWidth: 80,
                        Cell: row=>{
                            if(row.value!==0 && row.value%1===0){
                                return row.value+".0";
                            }
                            return row.value;
                        }
                    },{
                        Header: 'Chron exp',
                        accessor: "plchronexp",
                        minWidth: 80,
                        Cell: row=>{
                            if(row.value!==0 && row.value%1===0){
                                return row.value+".0";
                            }
                            return row.value;
                        }
                    },{
                        Header: 'Riflemen',
                        accessor: "plrifleman",
                        minWidth: 70
                    },{
                        Header: 'Engineers',
                        accessor: "plengineer",
                        minWidth: 70
                    },{
                        Header: 'Snipers',
                        accessor: "plsniper",
                        minWidth: 70
                    },{
                        Header: 'Medics',
                        accessor: "plmedic",
                        minWidth: 70
                    }]
                }
                showPagination = {false}
                defaultPageSize = {2}
            />
    }
}
