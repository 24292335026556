import React, { Component } from 'react';
import "./MapPreview.css";


export default class MapPreview extends Component{

    componentDidMount(){
        window.addEventListener("resize", this.resizeHandler)
        this.resizeHandler();
    }

    shouldComponentUpdate(nextprops){
        if(JSON.stringify(this.props)===JSON.stringify(nextprops)){
            return false;
        }
        return true;
    }
    
    componentDidUpdate(){
        this.resizeHandler();
    }

    componentWillUnmount(){
        window.removeEventListener("resize", this.resizeHandler)
    }

    canvas;
    canvashasevents = false;
    thumbimg;

    resizeHandler = ()=>{
        if(this.canvas){
            this.canvas.width = 200;
            this.canvas.height = 200;
            let parent = this.canvas.parentNode;
            this.canvas.width = parent.clientWidth;
            this.canvas.height = parent.clientHeight;

            let data = this.props.mappreviewdata;
            this.thumbimg = new Image();    //this should remove load event listener, if previously added?
            this.thumbimg.src = data.mapimgurl;

            if(!this.canvashasevents && data.townshistory.length>0){
                this.canvas.addEventListener("click", this.clickhandler.bind(this));
                this.canvas.addEventListener("mouseleave", this.mouseleavehandler.bind(this));
                this.canvas.addEventListener("mousemove", (ev)=>{this.mousemovehandler(ev, this)});
                this.canvashasevents = true;
            }
            this.updateMapPreview();
        }
    }

    clickhandler(){
        this.animate = true;
        this.currentframe = 0;
        //window.clearTimeout(window.draw);
        this.updateMapPreview();
    }

    mouseleavehandler(){
        this.animate = false;
        this.currentframe = 0;
        //window.clearTimeout(window.draw);
        this.updateMapPreview();
    }

    mousemovehandler(ev){
        this.animate = false;
        let x = this.getMouseXPos(ev, this.canvas);
        let xpercent = x/this.canvas.width;
        let historylen = this.props.mappreviewdata.townshistory.length;
        this.updateMapPreview(Math.floor(xpercent * (historylen-1)));
    }

    getMouseXPos(ev, canvas){
        let rect = canvas.getBoundingClientRect();
        return (ev.clientX - rect.left) / (rect.right - rect.left) * canvas.width;
        //y: (ev.clientY - rect.top) / (rect.bottom - rect.top) * canvas.height
    }

    currentframe = 0;
    animate = false;

    updateMapPreview(mmoveframe){
        let canvas = this.canvas;
        //let t1=performance.now();
        let parent = canvas.parentNode;
        if(canvas.width !== parent.clientWidth || canvas.height !== parent.clientHeight){
            canvas.width = parent.clientWidth;
            canvas.height = parent.clientHeight;
        }
        let ctx = canvas.getContext("2d");

        try{
            let data = this.props.mappreviewdata;
            let gametime = this.props.gametime;
            if(!this.thumbimg.complete){
                //setTimeout(()=>{this.updateMapPreview()}, 50);
                this.thumbimg.addEventListener("load",()=>{this.updateMapPreview()});
                return;
            }

            let mapwidth = data.mapwidth;
            let townmargin = 2000;
            let xmin = 100000;
            let xmax = 0;
            let ymin = 100000;
            let ymax = 0;
            
            let towns = data.towns;
            for(let i = 0; i < towns.length; i++){
                if(towns[i].x < xmin){xmin = towns[i].x}
                if(towns[i].x > xmax){xmax = towns[i].x}
                if(towns[i].y < ymin){ymin = towns[i].y}
                if(towns[i].y > ymax){ymax = towns[i].y}
            }
            
            let scale=1/(mapwidth/(canvas.width>canvas.height?canvas.width:canvas.height)), xtrans=0, ytrans=0;
            
            let scaletownx = 1/((xmax-xmin+townmargin)/canvas.width);
            let scaletowny = 1/((ymax-ymin+townmargin)/canvas.height);

            let minscale = 100000;
            if(scaletownx<minscale){minscale=scaletownx}
            if(scaletowny<minscale){minscale=scaletowny}
            scale = minscale;

            let xvismeters = canvas.width/minscale;
            let yvismeters = canvas.height/minscale;

            xtrans = -xmin + townmargin/2 + (xvismeters-(xmax-xmin)-townmargin)/2;
            ytrans = -mapwidth + ymax + townmargin/2 + (yvismeters-(ymax-ymin)-townmargin)/2;


            ctx.save();
            ctx.clearRect(0,0,canvas.width,canvas.height);
            ctx.scale(scale,scale);
            ctx.translate(xtrans, ytrans);
            ctx.drawImage(this.thumbimg,0,0,mapwidth,mapwidth);


            let history = data.townshistory;
            let faction1 = toShortFac(this.props.faction1);
            let faction2 = toShortFac(this.props.faction2);
            let animate = this.animate;
            let fidx = mmoveframe !== undefined ? mmoveframe : history.length-1;
            if(animate){
                fidx = this.currentframe;
            }

            //draw connecting lines
            ctx.lineWidth = 2/scale;
            for(let i=0; i<towns.length; i++){
                let town = towns[i];
                let connections = town.connections.split(" ");
                for(let ii=0; ii<connections.length; ii++){
                    if(parseInt(connections[ii].split("_")[1], 10) > parseInt(town.name.split("_")[1], 10)){

                        let factionofconnected;
                        let ppp = 0;
                        for(ppp; ppp<towns.length; ppp++){
                            if(towns[ppp].name === connections[ii]){
                                factionofconnected = history[fidx][ppp];
                                break;
                            }
                        }

                        let s = history[fidx][i];
                        if(s === factionofconnected && (s === faction1 || s=== faction2)){
                            fillCol(s);
                        }else{
                            fillCol("Z");
                        }
                        ctx.beginPath();
                        ctx.moveTo(town.x,mapwidth-town.y);
                        if(towns.hasOwnProperty(ppp)){
                            ctx.lineTo(towns[ppp].x, mapwidth-towns[ppp].y);
                        }
                        ctx.stroke();
                    }
                }
            }

            //draw circles
            for(let i=0; i<towns.length; i++){
                let x = towns[i].x;
                let y = towns[i].y;
                let s = history[fidx][i];
                if(s !== faction1 && s!== faction2){
                    s = "Z";
                }
                fillCol(s);
                ctx.beginPath();
                ctx.arc(x,mapwidth-y,8/scale,0,2*Math.PI);
                ctx.fill();
            }
            ctx.restore();
            
            //draw time
            if(!this.animate && mmoveframe === undefined){
                ctx.fillStyle = 'black';
                ctx.fillText(gametime, canvas.width-30, 10);
            }

            //draw progress bar
            if(this.animate || mmoveframe !== undefined){
                ctx.globalAlpha = 0.8;
                ctx.beginPath();
                fillCol("Z");
                ctx.lineWidth = 3;
                ctx.moveTo(0, canvas.height-4.5);
                let xpos = fidx/(history.length-1) * canvas.width;
                ctx.lineTo(xpos, canvas.height-4.5);
                ctx.stroke();
                ctx.globalAlpha = 1;
            }

            //console.log(performance.now()-t1);

            if(this.animate && this.currentframe+1 < history.length){
                this.currentframe += 1;
                window.setTimeout(()=>{this.updateMapPreview()}, 1200/ Math.pow(history.length, 0.9));
            }else if(this.animate){
                this.animate = false;
                this.updateMapPreview();
            }
        }catch(e){
            console.log(e);
            ctx.restore();
            ctx.fillText("Error", 5, 10);
        }

        function fillCol(side){
            if (side==='B'){ctx.fillStyle = 'blue'; ctx.strokeStyle= 'blue'; return;}
            if (side==='O'){ctx.fillStyle = 'red'; ctx.strokeStyle= 'red'; return;}
            if (side==='I'){ctx.fillStyle = 'green'; ctx.strokeStyle= 'green'; return;}
            ctx.fillStyle = 'black'; ctx.strokeStyle= 'black'; return;
        }

        function toShortFac(side){
            if(side==="Blufor"){return "B"}
            if(side==="Opfor"){return "O"}
            if(side==="Independent"){return "I"}
        }

    }
    

    render(){
        return(
            <canvas className="mappreviewcanvas" ref={(canv) => {this.canvas = canv}} width={200} height={200}/>
        )
    }
}
