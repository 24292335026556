export function toHHMM(seconds){
    var h, m, result='';

    h = Math.floor(seconds/3600);
    seconds -= h*3600;
    if(h){
        //result = h<10 ? '0'+h+'h ' : h+'h ';
        result = h+'h ';
    }

    m = Math.floor(seconds/60);
    seconds -= m*60;
    result += m<10 ? '0'+m+'m ' : m+'m ';

    return result;
}