import React from "react";
import {HorizontalBar} from 'react-chartjs-2';
import "./DonationsBanner.css"

function DonationsBanner(props){

    let donationsdata = props.donationsdata;

    let data = {
        labels:[""],
        datasets: [
            {   label: "donations",
                data: [donationsdata.quarterpercentage],
                backgroundColor: ["rgb(0, 140, 220)"],
                hoverBackgroundColor: ["rgb(0, 140, 240)"]
            }
        ]
    };

    let iswindowsmall = window.innerWidth<1300 ? 0 : 1;

    return(
        <a href="https://www.eutw.net/donate" 
            target="_blank" rel="noopener noreferrer" 
            className="donationsblock" 
            style={{
                fontSize: props.dnbannerconfig.size+"em",
                marginBottom : props.dnbannerconfig.pos === "bottom" ? "40px" : "0px",
                padding : props.dnbannerconfig.pos === "bottom" ? "10px" : "0px",
            }}
        >
            <div id="donationscont">
                <div id="donationstext">
                    <p id="donationstitle">{props.dnbannerconfig.msg}</p>
                    <p className="donationsline">Current goal: funding Q{donationsdata.quarter} of {donationsdata.year}</p>
                    <p className="donationsline">Completed: €{donationsdata.quarterstatus} of €{donationsdata.quarteramount}</p>
                </div>
                <div id="donationsbar">
                    <HorizontalBar
                        data={data}
                        width={300}
                        height={50}
                        options={{
                            animation:{duration: 600*iswindowsmall},
                            hover:{animationDuration: 0},
                            legend:{display: false},
                            responsive: true,
                            maintainAspectRatio: false,
                            scales:{
                                xAxes:[{
                                    display:false,
                                    ticks:{
                                        maxRotation:0,
                                        beginAtZero:true,
                                        suggestedMin:0,
                                        suggestedMax:100,
                                    }
                                }],
                                yAxes:[{
                                    display:false,
                                }]
                            },
                            
                            tooltips: {
                                callbacks: {
                                    label: (item, data)=>{
                                        return data.labels[item.index] +"Current goal: "+data.datasets[0].data[item.index] + "%";
                                    }
                                }
                            }
                        }}
                    
                    />
                </div>
            </div>
        </a>
    )
}

export default DonationsBanner;
