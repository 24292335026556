import React, { Component } from "react"
import "./HeaderBlock.css"

export default class HeaderBlock extends Component {
    constructor() {
        super()
        this.state = {
            sidebarOn: false
        }
        window.addEventListener("resize", () => {
            if (window.innerWidth > 1200 && this.state.sidebarOn) {
                this.setState({ sidebarOn: false })
            }
        })
        document.addEventListener("click", ev => {
            if (this.state.sidebarOn && ev.target !== this.refs.hamburgerBtn) {
                this.setState({ sidebarOn: false })
            }
        })
    }

    toggleSidebar() {
        this.setState({
            sidebarOn: !this.state.sidebarOn
        })
    }

    render() {
        return (
            <header>
                <div id="logo-wrap">
                    <a href="https://www.eutw.net">
                        <img
                            id="img-logo-light"
                            alt="EUTW logo"
                            src="/media/eutw-small-inverted.png"
                        />
                        <img
                            id="img-logo-dark"
                            alt="EUTW logo"
                            src="/media/eutw-small.png"
                        />
                    </a>
                </div>
                <div
                    id="header-btns-wrap"
                    className={
                        this.state.sidebarOn
                            ? "sidebar-visible"
                            : "sidebar-hidden"
                    }
                    ref="headerBtnsWrap"
                >
                    <div id="header-nav-block">
                        <div id="pusher" />
                        <a href="https://www.eutw.net">
                            <div className="header-btn">EUTW</div>
                        </a>
                        <a href="https://forums.eutw.net">
                            <div className="header-btn">Forums</div>
                        </a>
                        <a href="">
                            <div className="header-btn header-btn-selected">
                                Status
                            </div>
                        </a>
                        <a href="sessions">
                            <div className="header-btn">Sessions</div>
                        </a>
                        <a href="leaderboards">
                            <div className="header-btn header-btn-last">
                                Leaderboards
                            </div>
                        </a>
                    </div>
                </div>
                <div
                    id="hamburger-btn"
                    onClick={() => {
                        this.toggleSidebar()
                    }}
                    ref="hamburgerBtn"
                >
                    &#9776;
                </div>
            </header>
        )
    }
}
