import React, { Component } from 'react';
import ReactTable from 'react-table';
import 'react-table/react-table.css';


export default class PlayersTable extends Component{

    constructor(props){
        super(props);
        this.bgcolor = this.setColor(props,0.1);
        this.textcolor = this.setColor(props);
    }

    componentWillReceiveProps(nextprops){
        this.bgcolor = this.setColor(nextprops,0.1);
        this.textcolor = this.setColor(nextprops);
    }

    shouldComponentUpdate(nextprops){
        if(nextprops.updatefromclick || JSON.stringify(this.props.serverinfo)===JSON.stringify(nextprops.serverinfo)){
            return false;
        }
        return true;
    }

    setColor(props,alpha){
        alpha = alpha === undefined ? 1 : alpha;
        let faction = props.factionindex===0 ? props.serverinfo.faction1 : props.serverinfo.faction2;
        if(faction === "Blufor"){
            return 'rgba(0,0,240,'+alpha+')'
        }else if(faction === "Opfor"){
            return 'rgba(240,0,0,'+alpha+')'
        }else if(faction === "Independent"){
            return 'rgba(0,200,0,'+alpha+')'
        }
    }

    bgcolor;
    textcolor;
    shortClassToLong = {
        "R": "Rifleman",
        "E": "Engineer",
        "S": "Sniper",
        "M": "Medic"
    }

    render(){
        return <ReactTable

            getTableProps={(state, rowInfo, column, instance) => {
                return {
                onClick: (e, handleOriginal) => {
                    if (handleOriginal) {
                        handleOriginal()
                    }
                    e.stopPropagation();
                }
                }
            }}

            className = {"-striped -highlight"}

            data={this.props.factionindex===0 ? this.props.serverinfo.players.faction1 : this.props.serverinfo.players.faction2}
            noDataText={""}
            style= {{
                backgroundColor: this.bgcolor,
                fontSize: "12px",
                lineHeight: "0.5em",
                flex: "1"
            }}

            getTheadGroupThProps={(state, rowInfo, column, a)=>{
                return{
                    style: {
                        color: this.textcolor,
                        fontSize: "18px",
                        lineHeight: "16px"
                    }
                }
            }}

            getNoDataProps={()=>{
                return{
                    style: {display: "none"}
                }
            }}

            columns={[{
                Header: this.props.factionindex===0 ?
                    this.props.serverinfo.faction1+" ("+this.props.serverinfo.subfaction1+")" :
                    this.props.serverinfo.faction2+" ("+this.props.serverinfo.subfaction2+")",
                columns:
                    [{
                        id: "name",
                        Header: 'Name',
                        accessor: d=>d.n
                    },{
                        id: "exp",
                        Header: 'Exp',
                        accessor: d=>d.exp,
                        maxWidth: 100,
                        style: {
                            textAlign:"center"
                        }
                    },{
                        id: "playtime",
                        Header: 'Playtime',
                        accessor: d=>d.pt,
                        Cell: row=>{
                            let seconds = row.original.pt;
                            let hour = Math.floor(seconds/3600);
                            let minutes = Math.floor((seconds - (hour*3600))/60);
                            return ((hour===0 ? "" : hour + "h") + minutes + "m");
                        },
                        maxWidth: 100,
                        style: {
                            textAlign:"center"
                        }
                    },{
                        id: "class",
                        Header: 'Class',
                        accessor: d=>{return this.shortClassToLong[d.c]},
                        maxWidth: 150,
                        style: {
                            textAlign:"center"
                        }
                    }]
                
            }]}
            defaultSorted={[
                {
                id: "name",
                desc: false
                }
            ]}
            showPagination = {false}
            defaultPageSize = {30}
            resizable = {false}
            pageSize={ this.props.serverinfo.players.faction1.length > this.props.serverinfo.players.faction2.length ? 
                this.props.serverinfo.players.faction1.length : this.props.serverinfo.players.faction2.length
            }
        />
    }
}
