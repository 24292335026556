import React from "react";
import "./SubHeaderBlock.css"

//{<div className="subheaderblock" dangerouslySetInnerHTML={{__html:props.htmldata}} />}

function SubHeaderBlock(props){
    return(
        <div className="subheaderwrap" style={{backgroundColor: props.smbannerconfig.bgcol}}>
            <a href={props.smbannerconfig.href} >
                <div className="subheadermsg" style={{color: props.smbannerconfig.col}}>
                    {props.smbannerconfig.msg}
                </div>
            </a>
        </div>
    )
}

export default SubHeaderBlock;
