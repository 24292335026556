import React, { Component } from 'react';
import {Line, defaults} from 'react-chartjs-2';
import {toHHMM} from "../../common/SecondsToTime";

export class LineGraph extends Component{
    constructor(props){
        super(props);
        this.updatedata(props);
    }

    shouldComponentUpdate(nextprops){
        if(nextprops.updatefromclick || JSON.stringify(this.props)===JSON.stringify(nextprops)){
            return false;
        }
        this.updatedata(nextprops);
        return true;
    }

    updatedata(nextprops){
        if(nextprops.theme==="dark"){
            defaults.global.defaultFontColor = "#ccc";
        }
        this.data.labels = nextprops.graphdata.time;
        
        this.data.datasets[0].data = nextprops.graphdata.playerscount.faction1;
        this.setLineColor(this.data.datasets[0], nextprops.faction1);
        
        this.data.datasets[1].data = nextprops.graphdata.playerscount.faction2;
        this.setLineColor(this.data.datasets[1], nextprops.faction2);
    }

    data = {
        labels:[],
        datasets: [
            {   label: "1st dataset",
                data: [10,20,30,12,12,14],
                borderWidth: 1,
                pointHitRadius: 10,
                pointRadius: 1,
                pointHoverRadius: 1
            },{
                label: "2nd dataset",
                data: [12,23,20,22,12,24],
                borderWidth: 1,
                pointHitRadius: 10,
                pointRadius: 1,
                pointHoverRadius: 1
            }
        ]
    };

    setLineColor(dataset, faction){
        if(faction === "Blufor"){
            dataset.backgroundColor = 'rgba(0,0,255,0.2)';
            dataset.pointBackgroundColor = 'rgba(0,0,255,0.7)';
            dataset.borderColor = 'rgba(0,0,255,0.7)'
        }else if(faction === "Opfor"){
            dataset.backgroundColor = 'rgba(255,0,0,0.2)';
            dataset.pointBackgroundColor = 'rgba(255,0,0,0.7)';
            dataset.borderColor = 'rgba(255,0,0,0.7)'
        }else if(faction === "Independent"){
            dataset.backgroundColor = 'rgba(0,200,0,0.2)';
            dataset.pointBackgroundColor = 'rgba(0,200,0,0.7)';
            dataset.borderColor = 'rgba(0,200,0,0.7)'
        }
    }

    render(){
        return(
        <Line
            data={this.data}
            width={250}
            height={150}
            options={{
                animation: {duration: 0},
                hover: {animationDuration: 0},
                tooltips: {enabled: false},
                legend: {display: false},
                title: {display: false},
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    xAxes: [{
                        ticks: {
                            maxTicksLimit: 5,
                            maxRotation: 0,
                            callback: (val,idx, values)=>{
                                return toHHMM(val);
                            }
                        }
                    }],
                    yAxes: [{
                        ticks: {
                            suggestedMin: 0,
                            suggestedMax: 30,
                            stepSize: 10
                        }
                    }]
                }
            }}
        />
        )
    }
}
