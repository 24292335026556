import React, { Component } from "react"
import "./App.css"
import requestdata from "./common/fetch"
import HeaderBlock from "./components/HeaderBlock"
import SubHeaderBlock from "./components/SubHeaderBlock"
import ServerBox from "./components/serverblock/ServerBox"
import DonationsBanner from "./components/DonationsBanner"
import WeekStats from "./components/WeekStats"

class App extends Component {
    constructor() {
        super()
        //var Theme = localStorage.getItem("theme")==null? "light" : localStorage.getItem("theme");
        //var Theme = "dark";
        var Theme = document.cookie.includes("stats_theme=dark")
            ? "dark"
            : "light"
        this.state = {
            serversdata: [],
            smbannerconfig: false,
            dnbannerconfig: false,
            donationsdata: false,
            selectedserver: -1,
            updatefromclick: false,
            theme: Theme
        }
    }

    loadingFirst = true
    updateInterval = 30000
    updateCounter = 0

    componentDidMount() {
        this.updateData()
    }

    updateData() {
        requestdata(
            "https://stats.mik-ari.com/api/status/get_live_data.php"
        ).then(res => {
            this.loadingFirst = false
            setTimeout(() => {
                this.updateData()
            }, this.updateInterval)

            this.setState({
                serversdata: res.serversdata,
                smbannerconfig: res.smbannerconfig,
                dnbannerconfig: res.dnbannerconfig,
                donationsdata: res.donationsdata,
                weekstats: res.weekstats,
                updatefromclick: false
            })

            this.updateCounter++
        })
    }

    handleclick(key, element) {
        if (key === this.state.selectedserver) {
            this.setState({
                selectedserver: -1,
                updatefromclick: true
            })
        } else {
            this.setState({
                selectedserver: key,
                updatefromclick: true
            })
        }

        //scroll to serverbox animation:
        if (
            window.innerWidth < 800 &&
            (this.state.selectedserver === -1 ||
                key !== this.state.selectedserver)
        ) {
            let originaly = window.scrollY
            //scroll only if the "server block" upper edge can be seen, is below the top of screen
            if (element.offsetTop > originaly) {
                setTimeout(() => {
                    let sval = 0
                    console.log(element.offsetTop, originaly, window.scrollY)
                    scrolltoy(element, originaly, sval)
                }, 10)
            }
        }

        function scrolltoy(elem, originaly, sval) {
            requestAnimationFrame(() => {
                let yval = elem.offsetTop * sval + originaly * (1 - sval)
                window.scrollTo(0, yval)
                sval = sval + 0.1
                if (sval <= 1) {
                    scrolltoy(elem, originaly, sval)
                }
            })
        }
    }

    render() {
        return (
            <div className={"App " + this.state.theme}>
                <HeaderBlock />

                {this.state.smbannerconfig &&
                this.state.smbannerconfig.msg !== "" ? (
                    <SubHeaderBlock
                        smbannerconfig={this.state.smbannerconfig}
                    />
                ) : null}

                {this.state.dnbannerconfig && this.state.donationsdata ? (
                    this.state.dnbannerconfig.pos === "top" ? (
                        <DonationsBanner
                            dnbannerconfig={this.state.dnbannerconfig}
                            donationsdata={this.state.donationsdata}
                        />
                    ) : null
                ) : null}

                <div
                    className={
                        "serverswrap " +
                        (this.loadingFirst ? "loadingFirst" : "")
                    }
                >
                    {this.state.serversdata ? (
                        this.state.serversdata.map((server, key) => (
                            <ServerBox
                                key={key}
                                id={key}
                                theme={this.state.theme}
                                expand={
                                    key === this.state.selectedserver
                                        ? true
                                        : false
                                }
                                updatefromclick={this.state.updatefromclick}
                                serverinfo={server}
                                clickaction={(key, element) => {
                                    this.handleclick(key, element)
                                }}
                            />
                        ))
                    ) : (
                        <h3 style={{ fontWeight: 100 }}>
                            Error retrieving data
                        </h3>
                    )}
                </div>

                {this.loadingFirst ? (
                    <div className="spinnerhidder">
                        <div className="spinner" />
                    </div>
                ) : null}

                {this.state.dnbannerconfig && this.state.donationsdata ? (
                    this.state.dnbannerconfig.pos === "bottom" ? (
                        <DonationsBanner
                            dnbannerconfig={this.state.dnbannerconfig}
                            donationsdata={this.state.donationsdata}
                        />
                    ) : null
                ) : null}

                {this.state.weekstats ? (
                    <WeekStats
                        data={this.state.weekstats}
                        theme={this.state.theme}
                    />
                ) : (
                    <div />
                )}

                {this.state.serversdata &&
                this.state.serversdata.length !== 0 ? (
                    <footer>
                        <a
                            href="https://stats.mik-ari.com/extra/layoutlist/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            EUTW Layouts
                        </a>
                        <a
                            href="https://www.desmos.com/calculator/ecsmcuxkie/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Experience graph
                        </a>
                    </footer>
                ) : (
                    <div />
                )}
            </div>
        )
    }
}

export default App
